exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-images-full-image-component-js": () => import("./../../../src/pages/images/FullImageComponent.js" /* webpackChunkName: "component---src-pages-images-full-image-component-js" */),
  "component---src-pages-images-image-component-js": () => import("./../../../src/pages/images/ImageComponent.js" /* webpackChunkName: "component---src-pages-images-image-component-js" */),
  "component---src-pages-images-index-js": () => import("./../../../src/pages/images/index.js" /* webpackChunkName: "component---src-pages-images-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-price-list-castorama-index-js": () => import("./../../../src/pages/priceListCastorama/index.js" /* webpackChunkName: "component---src-pages-price-list-castorama-index-js" */),
  "component---src-pages-price-list-delivery-index-js": () => import("./../../../src/pages/priceListDelivery/index.js" /* webpackChunkName: "component---src-pages-price-list-delivery-index-js" */),
  "component---src-pages-price-list-door-index-js": () => import("./../../../src/pages/priceListDoor/index.js" /* webpackChunkName: "component---src-pages-price-list-door-index-js" */),
  "component---src-pages-price-list-panels-index-js": () => import("./../../../src/pages/priceListPanels/index.js" /* webpackChunkName: "component---src-pages-price-list-panels-index-js" */),
  "component---src-pages-price-list-wardrobe-index-js": () => import("./../../../src/pages/priceListWardrobe/index.js" /* webpackChunkName: "component---src-pages-price-list-wardrobe-index-js" */)
}

